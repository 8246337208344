import FormField from "@sharedComponents/FormField";

const FormFields = (props) => {
  const fieldKey = props?.dataPlasmicCanvasEnvs;
  return (
    <FormField key={fieldKey} id={fieldKey} {...props} />
  )
};

export default FormFields;
