export const formValidators = (value, allValues, meta) => {
  if (!value) return null;

  const validators = [];
  switch (meta.name) {
    case "email":
      validators.push(isValidEmail, isCompanyEmail, isCompetitorEmail);
      break;
    default:
      break;
  }

  if (process.env.NEXT_PUBLIC_LOG_LEVEL === "debug") {
    !!validators && console.log("Validation Meta: ", meta);
  }

  return !!validators
    ? composeValidators(...validators)(value, allValues, meta)
    : null;
}

// Helper function to combine validators on a single field
const composeValidators = (...validators) => (value, allValues, meta) =>
  validators.reduce((error, validator) => error || validator(value, allValues, meta), undefined)

// Validate using NeverBounce API
const isValidEmail = (value, allValues, meta) => 
  value && (
    meta.data.neverbounceStatus === "invalid" ||
    meta.data.neverbounceStatus === "disposable")
    ? "Please enter a valid email address"
    : null;

// Prevent submission with non-business/company domains
const isCompanyEmail = (value) =>
  value &&
  ( value.includes("@gmail.") ||
    value.includes("@hotmail.") ||
    value.includes("@live.") ||
    value.includes("@yahoo.") ||
    value.includes("@outlook.") ||
    value.includes("@aol.") ||
    value.includes("@msn."))
    ? "Please use a company email address"
    : null;

// Prevent submission with competitor domains
const isCompetitorEmail = (value) =>
  value &&
  ( value.includes("@cisco.") ||
    value.includes("@mimecast.") ||
    value.includes("@proofpoint.") ||
    value.includes("@agari.") ||
    value.includes("@area1security.") ||
    value.includes("@armorblox.") ||
    value.includes("@avanan.") ||
    value.includes("@darktrace.") ||
    value.includes("@greathorn.") ||
    value.includes("@inky.") ||
    value.includes("@ironscales.") ||
    value.includes("@material.security") ||
    value.includes("@perception-point.io") ||
    value.includes("@tessian.") ||
    value.includes("@vadesecure.") ||
    value.includes("@clearedin.") ||
    value.includes("@egress.") ||
    value.includes("@fortra.") ||
    value.includes("@helpsystems.") ||
    value.includes("@cloudflare.") ||
    value.includes("@titanhq.") ||
    value.includes("@checkpoint.") ||
    value.includes("@barracuda.") ||
    value.includes("@trendmicro.") ||
    value.includes("@slashnext.") ||
    value.includes("@fortinet."))
    ? "Please use a valid email address"
    : null;

export default formValidators;
