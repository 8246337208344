import { PlayIcon } from "@abnormalComponents/Icons";
import { Box, Button, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay } from "@chakra-ui/react";
import { useState } from "react";
import EmbeddedVideoPlasmic from "./EmbeddedVideoPlasmic";

interface IProps {
  className?: string;
  autoplay?: boolean;
  centered?: boolean;
  controls?: boolean;
  iconColor?: string;
  iconColorHover?: string;
  playMuted?: boolean;  
  playIconWidth?: string;
  returnFocusOnClose?: boolean;
  showPlayIcon?: boolean;
  size?: string;
  videoCoverAltDesc?: string;
  videoCoverImage?: string | any;
  videoUrl?: string;
}

const ModalVideoPlasmic = ({
  className,
  autoplay,
  centered,
  controls,
  iconColor = "#6863F2",
  iconColorHover = "#8985f2",
  playIconWidth,
  playMuted,
  returnFocusOnClose = true,
  showPlayIcon = true,
  size = "xl",
  videoCoverAltDesc,
  videoCoverImage,
  videoUrl,
}: IProps) => {
  if (!videoUrl || !videoCoverImage) return null;

  const [isOpen, setIsOpen] = useState(false);

  const onClose = () => setIsOpen(false);
  const onOpen = () => setIsOpen(true);

  return (
    <Box
      className={className}
      cursor="pointer"
      onClick={onOpen}
      position="relative"
      w="100%"
    >
      <Image
        src={typeof videoCoverImage === "string" ? videoCoverImage : videoCoverImage?.src}
        alt={videoCoverAltDesc}
        w="100%"
        // maxHeight="209px"
      />
      <Button
        position="absolute"
        width="100%"
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        top="0"
        left="0"
        variant="unstyled"
        color={iconColor}
        _focus={{ color: iconColorHover, outline: "none" }}
        _hover={{ color: iconColorHover }}
      >
      { showPlayIcon && <Box w={playIconWidth || "101px"}><PlayIcon className="max-w-full"/></Box> }
      </Button>
      <Modal
        isCentered={centered}
        isOpen={isOpen}
        onClose={onClose}
        blockScrollOnMount={false}
        returnFocusOnClose={returnFocusOnClose}
        size={size}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton zIndex="9999" />
          <ModalBody>
            <EmbeddedVideoPlasmic
              src={videoUrl}
              autoplay={autoplay}
              controls={controls}
              iconColor={iconColor}
              loop={false}
              playsInline={true}
              playMuted={playMuted}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ModalVideoPlasmic;
