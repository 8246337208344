import theme from "@abnormal/chakra.theme.js";
import { ChakraProvider } from "@chakra-ui/react";
import { GlobalContextMeta } from "@plasmicapp/host";
import registerComponent from "@plasmicapp/host/registerComponent";
import registerGlobalContext from "@plasmicapp/host/registerGlobalContext";
import { ReactNode } from "react";

export const CHAKRA_UI_IMPORT_PATH = "@chakra-ui/react";

export const getPlasmicComponentName = (componentName: string) =>
	`chakra-ui-${componentName}`;

export const getDisplayComponentName = (componentName: string) =>
	`Chakra-UI ${componentName}`;

export const getComponentNameAndImportMeta = (
	componentName: string,
	parentComponentName?: string,
	opts?: {
		displayName?: string;
		importPath?: string;
	}
) => ({
	name: getPlasmicComponentName(componentName),
	displayName: opts?.displayName ?? getDisplayComponentName(componentName),
	importPath: opts?.importPath ?? CHAKRA_UI_IMPORT_PATH,
	importName: componentName,
	...(parentComponentName
		? { parentComponentName: getPlasmicComponentName(parentComponentName) }
		: {}),
});

type PlasmicChakraProviderProps = {
	children?: ReactNode;
};

export function PlasmicChakraProvider(props: PlasmicChakraProviderProps) {
	return <ChakraProvider theme={theme}>{props.children}</ChakraProvider>;
}

type Registerable = {
	registerComponent: typeof registerComponent;
	registerGlobalContext: typeof registerGlobalContext;
};

export function registerChakraProvider(
	loader?: Registerable,
	customChakraProviderMeta?: GlobalContextMeta<PlasmicChakraProviderProps>
) {
	const doRegisterComponent: typeof registerGlobalContext = (...args) =>
		loader
			? loader.registerGlobalContext(...args)
			: registerGlobalContext(...args);
	doRegisterComponent(PlasmicChakraProvider, customChakraProviderMeta);
}
