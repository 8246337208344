import { FormFieldData, SubmittedFormData } from "@sharedInterfaces/FormData.interface";
import { convertRegion, TO_ABBREVIATED } from "@sharedLib/convertRegion";
import { IncomingWebhook } from "@slack/webhook";
import cookieCutter from "cookie-cutter";

/* Types */
interface IProps {
  postData: SubmittedFormData;
  fieldTransforms?: { [key: string]: boolean }
  formFields: FormFieldData[];
  onSuccess?: () => void;
  onError?: () => void;
}

/* Submit the form */
export const submitForm = async ({
  postData,
  fieldTransforms,
  formFields,
  onSuccess = () => { },
  onError = () => { }
}: IProps) => {
  /* If no post data or window object, return null */
  if (typeof window === "undefined" || !postData) return null;

  /* Loop through values and perform data transformations */
  Object.keys(postData?.fields).forEach((field) => {
    // if (process.env.NEXT_PUBLIC_LOG_LEVEL === "debug") {
    //   console.log("Field: ", field);
    // }
    // Convert state to abbreviation for Marketo
    if (field === "state" && fieldTransforms["state"] && !!postData?.fields[field]) {
      // console.log("Located State Field: ",values[field]);
      postData.fields[field] = convertRegion(postData?.fields[field], TO_ABBREVIATED);
    }
    // If the field is a date field and has a value, convert it to ISO string for Marketo
    else if (formFields.find((formField) => formField.fieldName === field).fieldType.toLowerCase().includes("datetime") && !!postData?.fields[field]) {
      // console.log("Located DateTime Field: ",values[field]);
      postData.fields[field] = new Date(postData?.fields[field]).toISOString();
    }
  });

  /* Set the form data */
  postData.marketoCookie = cookieCutter.get("_mkto_trk");
  postData.fields["referralURL"] = window.location.href;
  postData.pageUrl = window.location.href;

  /* Fetch analytics tracking data */
  if (window?.ga && process.env.NEXT_PUBLIC_GTM_ID) {
    window.ga(function () {
      const tracker = window.ga.getAll()[0];
      postData.fields["GATRACKID__c"] = tracker.get("trackingId");
      postData.fields["GACLIENTID__c"] = tracker.get("clientId");
      postData.fields["GAUSERID__c"] = tracker.get("userId");
    });
  }
  
  let result;

  /* Debug post data submission, sent to Slack + Marketo */
  if (process.env.NEXT_PUBLIC_LOG_LEVEL === "debug") {
    console.log("Post Data :", postData)
  }

  if (
    process.env.NEXT_PUBLIC_SLACK_WEBHOOK /* Located in Vercel Environment Variables */
  ) {
    const post = await fetch(
      `${process.env.NEXT_PUBLIC_CMS_URL}/api/submitForm`,
      {
        body: JSON.stringify(postData),
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    result = await post.json();

    const webhookUrl = process.env.NEXT_PUBLIC_SLACK_WEBHOOK;

    const webhook = new IncomingWebhook(webhookUrl);
    delete webhook["axios"].defaults.headers["User-Agent"];
    webhook["axios"].defaults.headers["Content-Type"] =
      "application/x-www-form-urlencoded";

    const fieldsBlocks = [
      postData.fields["email"] ? {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `*email*: ${postData.fields["email"]}`,
        },
      } : {
        type: "header",
        text: {
          type: "plain_text",
          text: `No Form Data`,
          emoji: true,
        },
      }
    ];

    Object.keys(postData.fields).forEach((field) => {
      if (!!postData.fields[field] && field !== "email" && field !== "referralURL") {
        fieldsBlocks.push({
          type: "section",
          text: {
            type: "mrkdwn",
            text: `*${field}*: ${postData.fields[field]}`,
          },
        });
      }
    });

    if (result.result[0]?.reasons === undefined) {
      webhook.send({
        attachments: [
          {
            color: "#00ff00",
            blocks: [
              {
                type: "header",
                text: {
                  type: "plain_text",
                  text: `Form Submission: ${postData.formName}`,
                  emoji: true,
                },
              },
              ...fieldsBlocks,
              {
                type: "section",
                text: {
                  type: "mrkdwn",
                  text: `*Result:* ${result.result[0].status
                    }\n*Reason:*\n\`\`\`${JSON.stringify(
                      result.result[0]?.reasons
                    )}\`\`\``,
                },
              },
              {
                type: "section",
                text: {
                  type: "mrkdwn",
                  text: `*Form Data:*\n\`\`\`${JSON.stringify(
                    postData
                  )}\`\`\``,
                },
              },
              {
                type: "section",
                text: {
                  type: "mrkdwn",
                  text: `*Referral URL:* ${postData.fields["referralURL"]}\n\n<https://app-sj31.marketo.com/leadDatabase/loadLeadDetail?leadId=${result.result[0].id}|View Lead in Marketo>`,
                },
              },
            ],
          },
        ],
      });
    } else {
      webhook.send({
        attachments: [
          {
            color: "#ff0000",
            blocks: [
              {
                type: "header",
                text: {
                  type: "plain_text",
                  text: "Website Form Submission Failed",
                  emoji: true,
                },
              },
              {
                type: "section",
                text: {
                  type: "mrkdwn",
                  text: `*Users Notified: <@U05JA8KKC4T>\n*Form Name*: ${postData.formName} \n*Referral URL:* ${postData.fields["referralURL"]}`,
                },
              },
              ,
              {
                type: "section",
                text: {
                  type: "mrkdwn",
                  text: `\`\`\`${JSON.stringify(result)}\`\`\`\``,
                },
              },
            ],
          },
        ],
      });
    }
  } else {
    /* If no Slack webhook is set, just return a success */
    result = { success: true };
  }

  if (result.success === true) {
    /* Set expiration date for cookies */
    const expireDate = new Date();
    expireDate.setDate(expireDate.getDate() + 60);

    /* Store visible fields by fieldName: value in an object */
    const storedValues = formFields.reduce((acc, field) => {
      if (!postData?.fields[field.fieldName] || field.fieldType === "hidden") return acc;
      acc[field.fieldName] = postData?.fields[field.fieldName];
      return acc;
    }, {});
    if (process.env.NEXT_PUBLIC_LOG_LEVEL === "debug") {
      console.log("Stored Values: ", storedValues);
    }

    /* Merge the new stored values into the existing cookie values */
    const storedFormValues = {
      ...(cookieCutter.get("formValues") &&
        JSON.parse(cookieCutter.get("formValues"))),
      ...storedValues,
    };

    /* Save the updated cookie */
    cookieCutter.set("formValues", JSON.stringify(storedFormValues), {
      expires: expireDate,
    });

    /* Debug form fill data */
    // if (process.env.NEXT_PUBLIC_LOG_LEVEL === "debug") {
    //   console.log("Form Fill Label :", postData.formName)
    //   console.log("Form Fill Value :", window.location.href)
    // }

    if (window.dataLayer && process.env.NODE_ENV !== "development") {
      // Fire Google Form Fill Event
      window.dataLayer.push({
        event: "event",
        eventProps: {
          category: "Form",
          action: "Form Fill",
          label: postData.formName,
          value: window.location.href,
        },
        formData: {
          email: postData.fields?.email,
          phone: postData.fields?.phone,
        }
      });
    }

    /* Fire the callback fn */
    onSuccess();
  } else {
    /* Fire the callback fn */
    onError();
  }

  // TODO: alternatively, fire an event to Google Tag Manager test
};

export default submitForm;
