import { NextRouter } from "next/router"

export const parseRouter = (router: NextRouter) => {
  const basePath = router.asPath.split("?")[0].split("#")[0]
  let params = ""
  if (router.asPath.includes("?")) {
    const paramArray = router.asPath.split("?").join("&").split("&")
    paramArray.map((queryString, index) => {
      // Remove hash from query string
      if (queryString.includes("#")) {
        paramArray[index] = queryString.replace(/#.*/, "")
      }
    })
    // Remove basePath (the path before the first ?) from the parameters array
    paramArray.shift()
    // Reconstruct the query string, less the base path
    params = "?" + paramArray.join("&")
  }
  return {
    basePath,
    params,
  };
}

export default parseRouter;
